<template>
  <div class="home">
    <div>
      <h1>照片采集</h1>
      <video ref="videoElement" width="100%" height="auto" autoplay></video>
      <button @click="startCamera" style="font-size: 20px; padding: 15px;">启动相机</button>
      <button @click="takePhoto" :disabled="!isCameraOn" style="font-size: 20px; padding: 15px;">拍照</button>

      <div v-if="capturedPhoto">
        <h2>Preview</h2>
        <img :src="capturedPhoto" alt="Captured" width="100%">
        <button @click="downloadPhoto" style="font-size: 20px; padding: 15px;">保存照片</button>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCameraOn: false,
      capturedPhoto: null,
      mediaStream: null,
    };
  },
  methods: {
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.mediaStream = stream;
        this.$refs.videoElement.srcObject = stream;
        this.isCameraOn = true;
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    },
    takePhoto() {
      if (this.isCameraOn) {
        const canvas = document.createElement('canvas');
        canvas.width = this.$refs.videoElement.videoWidth;
        canvas.height = this.$refs.videoElement.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(this.$refs.videoElement, 0, 0, canvas.width, canvas.height);
        const photoUrl = canvas.toDataURL('image/png');
        this.capturedPhoto = photoUrl;
      }
    },
    downloadPhoto() {
      const link = document.createElement('a');
      link.href = this.capturedPhoto;
      link.download = 'captured-photo.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  beforeDestroy() {
    // Stop the camera when the component is destroyed
    if (this.mediaStream) {
      const tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop());
    }
  },
};
</script>

<style>
a {
  border: 1px solid #333; /* 添加1像素实线边框，可根据需要更改颜色和样式 */
  font-size: 18px;       /* 设置字体大小，可根据需要更改 */
  padding: 10px 20px;    /* 添加一些内边距，以改善按钮的外观，可根据需要更改 */
  text-decoration: none; /* 移除链接的下划线效果，可根据需要更改 */
  display: inline-block; /* 使链接表现为块级元素，以便设置宽度、高度等属性 */
  border-radius: 5px;    /* 设置边框半径，可根据需要更改 */
  color: #333;           /* 设置文本颜色，可根据需要更改 */
}

a:hover {
  background-color: #f0f0f0; /* 鼠标悬停时的背景颜色，可根据需要更改 */
}
</style>
